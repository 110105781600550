<template>
  <dialog-component
    :title="primaryDataValue"
    :visible="dialogVisible"
    @before-close="handleClose"
    :containerMaxHeight="'90vh'"
  >
    <template v-if="isMultiple">
      <CustomDashboardTable
        v-if="showTable"
        :component="component"
        :entity_id="entityId"
        :customFilters="[]"
        :entity_data_ids="entityDataId"
        :hide_options="true"
        :isFromApplicationuser="isFromApplicationuser"
        :hasViewPermission="false"
        :hasEditPermission="true"
        :hide_expend="true"
        :hide_export="true"
      ></CustomDashboardTable>
    </template>
    <div
      style="max-height: 65vh; overflow-y: auto; padding: 20px"
      :class="getAllSteps.length == 1 ? '' :'scrollable-div'"
      v-else
    >
      <el-breadcrumb separator-class="el-icon-arrow-right" v-if="entityData && isTitleVisible">
        <el-breadcrumb-item
          >{{ entityRefName }} ( {{ entityData.name }} )</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ primaryDataValue }}</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider v-if="isTitleVisible"></el-divider>

      <el-collapse
        accordion
        v-model="activeTemplateStep"
        v-if="getAllSteps.length > 1"
      >
        <el-collapse-item
          v-for="(step, index) of getAllSteps"
          :key="index"
          :name="index + 1"
        >
          <template slot="title">
            ({{ index + 1 }}/{{ getAllSteps.length }}).
            {{ step.templateInfo.name | truncate(25, "...") }}
          </template>
          <PreviewTemplate
            :templateData="step.templateInfo"
            :has-next="false"
            :entityDataId="entityDataId"
            :onlyView="true"
          />
        </el-collapse-item>
      </el-collapse>
      <!-- <el-tabs
      type="border-card"
      v-if="getAllSteps.length > 1"
      v-loading="loading"
    >
      <el-tab-pane v-for="(step, index) of getAllSteps" :key="index">
        <span slot="label">
          <img
            class="ml-2 mr-1"
            v-if="step.templateInfo.icon"
            :src="
              require('@/assets/img/template-icons/' + step.templateInfo.icon)
            "
            alt="icon"
            width="20"
          />
          {{ step.templateInfo.name | truncate(25, "...") }}
        </span>
        <PreviewTemplate
          :templateData="step.templateInfo"
          :has-next="false"
          :entityDataId="entityDataId"
          :onlyView="true"
        />
      </el-tab-pane>
    </el-tabs> -->

      <div v-if="getAllSteps.length == 1">
        <PreviewTemplate
          :templateData="getAllSteps[0].templateInfo"
          :has-next="false"
          :entityDataId="entityDataId"
          :onlyView="true"
        />
      </div>
    </div>
  </dialog-component>
</template>

<script>
import { mapGetters } from "vuex";
import PreviewTemplate from "./../templates/PreviewTemplate";

export default {
  components: {
    PreviewTemplate,
    CustomDashboardTable: () =>
      import("../customDashboard/customDashboardTable.vue"),
  },

  props: [
    "entityId",
    "entityDataId",
    "entityRefName",
    "primaryDataValue",
    "isMultiple",
    "isTitleVisible",
  ],

  mixins: [],
  data() {
    return {
      loading: false,
      step: 1,
      slug: null,
      layout: "div",
      userId: "",
      userProfileSideMenu: ["1"],
      entityData: null,
      dialogVisible: true,
      title: "",
      activeTemplateStep: 1,
      component: {},
      showTable: false,
      isFromApplicationuser: false
    };
  },
  async mounted() {
    if(this.$route.path.startsWith('/ap')){
      this.isFromApplicationuser = true;
    }
    if (!this.isMultiple) {
      await this.getData();
      this.title =
        this.entityRefName +
        ">" +
        this.primaryDataValue +
        `(${this.entityData.name})`;
    } else {
      this.title = this.entityRefName;
      this.getTableConfigurable();
    }
  },

  created() {},
  computed: {
    ...mapGetters("entities", ["getEntityDataById"]),
    getAllSteps() {
      return this.getEntityDataById ? this.getEntityDataById.templates : [];
    },

    getEntity() {
      return this.getEntityDataById;
    },

    getStepUrl() {
      return (step) => {
        return `/entity/ABC/${step.templateInfo.slug}`;
      };
    },

    activeStep() {
      return (step) => {
        if (this.$route.params.template_code === step.slug) {
          return true;
        }
        return false;
      };
    },
    getStepsObject() {
      if (this.getEntityDataById && this.getEntityDataById.templates) {
        const data = {};
        this.getEntityDataById.templates.forEach((step, index, arr) => {
          // it will use to determine finish step
          step.has_next = index < arr.length - 1;
          data[step.templateInfo.slug] = step;
        });

        return data;
      }
      return {};
    },
    getCurrentStep() {
      return (currentSlug) => {
        if (this.getStepsObject && currentSlug) {
          const step = this.getStepsObject[currentSlug];
          return step;
        }

        return null;
      };
    },
  },
  methods: {
    getTableConfigurable() {
      this.component = {
        component_name: this.title,
        entity_id: this.entityId,
      };
      this.showTable = true;
    },
    gotoNextStep() {
      const code = this.$route.params.template_code;
      const currentStepIndex = this.getAllSteps.findIndex(
        (x) => x.slug == code
      );
      if (
        currentStepIndex > -1 &&
        currentStepIndex < this.getAllSteps.length - 1
      ) {
        this.gotoStep(this.getAllSteps[currentStepIndex + 1]);
      }
    },
    async backToEntityList() {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({
        path: "/entity",
        query: query,
      });
    },
    async getData() {
      this.loading = true;

      await this.$store.dispatch("entities/fetchEntityById", {
        entity_id: this.entityId,
      });
      this.entityData = { ...this.getEntityDataById };
      if (this.getEntityDataById) {
        this.entityName = this.getEntityDataById.name;
        this.description = this.getEntityDataById.description;
      }
      this.loading = false;
    },
    handleClose() {
      this.$emit("close", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollable-div::-webkit-scrollbar {
  width: 10px;
}

.scrollable-div::-webkit-scrollbar-thumb {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background-clip: padding-box;
  border: 10px solid rgba(0, 0, 0, 0.19);
}
</style>
